.cardContainer {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--primary-color);
    padding: 0.8rem;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.cardContainer:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    transition: 0.4s;
}