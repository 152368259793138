.linkBox {
    background-color: #F8F9FD;
    border-radius: 10px;
    font-size: 0.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 90px;
    color: var(--primary-color);
    text-decoration: none;
    cursor: pointer;
}

.linkBoxContainer {
    gap: 10px;
}