.options__container {
    position: absolute;
    max-height: 210px;
    overflow: auto;
    min-width: 400px;
    color: black;
    padding: 0.5rem;
    border-radius: 0.25rem;
    box-shadow: 0px 20px 40px rgba(196, 196, 196, 0.2);
    z-index: 2;
}

.options__item {
    color: #999;
    padding: 5px;
    text-align: left;
    padding: 0.3 0.7rem;
}

.options__item:hover {
    background-color: lightgray;
    border-radius: 0.25rem;
    cursor: pointer;
}

.options__text {
    color: #333;
}