.avatar {
    border-radius: 50px;
    object-fit: contain;
    background-color: var(--grey-color);
}

.initialsAvatar {
    border-radius: 50px;
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-blue-color);
    border: 1px solid #14A3B8;
}