.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #ffffff;
    background-color: #061B5A;
    margin-bottom: 0.5rem;
}

.avatarAdd {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.avatarText {
    font-size: 0.7rem;
    max-width: 70px;
    text-align: center;
}