.summaryCircleLarge {
    background-color: var(--primary-color);
    border-radius: 50%;
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.7rem;
    font-weight: 900;
}

.summaryCircleLargeBull {
    height: 3px;
    width: 3px;
    border: 5px solid var(--primary-color);
    border-radius: 5px;
}

.summaryCircleSmall {
    margin-top: -30px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 900;
}

@media only screen and (min-width: 768px) {
    .summaryCircleSmall {
        height: 100px;
        width: 100px;
        font-size: 1.6rem;
    }

    .summaryCircleLarge {
        height: 200px;
        width: 200px;
        font-size: 1.8rem;
    }
}

@media only screen and (min-width: 768px) {
    .summaryCircleLarge {
        height: 270px;
        width: 270px;
        font-size: 2rem;
    }

    .summaryCircleSmall {
        height: 130px;
        width: 130px;
        font-size: 1.6rem;
        margin-top: -50px;
    }
}