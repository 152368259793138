.link {
    /* display: inline-block; */
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    width: 100%;
    border: 1px solid #595656;
    border-radius: 10px;
    margin-bottom: 8px;
    color: #C4C4C4;
    text-decoration: none;
    padding: 0.7rem 1.3rem;
    font-weight: bold;
    font-size: 0.8rem;
}

.listTitle {
    padding-left: 1.3rem;
}

.linkActive {
    color: #ffffff;
    background-color: var(--primary-color);
}

@media only screen and (min-width: 576px) {
    .link {
        display: block;
        border: none;
        border-radius: 0;
        font-size: 0.9rem;
    }
}