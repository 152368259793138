* {
  box-sizing: border-box;
}

body {
  /* overflow-x: hidden; */
  background-color: #f8f9fd;
  font-family: -apple-system, 'Montserrat', "Helvetica Neue", sans-serif;
}

:root {
  --primary-color: #061B5A;
  --warning-color: #EFB61C;
  --secondary-color: #14A3B8;
  --danger-color: #FF725E;
  --light-blue-color: #E7F1FE;
  --grey-color: #C4C4C4;
}

nav {
  /* position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  padding: 3em; */
  background: var(--primary-color);
}

.bgPrimary {
  background-color: var(--primary-color);
}

.bgSecondary {
  background-color: var(--secondary-color);
}

.bgDanger {
  background-color: var(--danger-color);
}

.bgWhite {
  background-color: #ffffff;
}

.bgWarning {
  background-color: var(--warning-color);
}

.bgLightBlue {
  background-color: var(--light-blue-color);
}

.customFormControl {
  background-color: var(--light-blue-color);
  border-color: var(--light-blue-color);
}

.tableHeader {
  background-color: #F8F9FD;
}

.tableHeader>tr>th {
  font-weight: lighter;
}

.textPrimary {
  color: var(--primary-color);
}

.textWarning {
  color: var(--warning-color);
}

.textSecondary {
  color: var(--secondary-color);
}

.fwNormal {
  font-weight: 500;
}

.cardShadow {
  box-shadow: 0px 20px 40px rgba(196, 196, 196, 0.2);
}

.buttonShadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.hoverLightBlue:hover {
  background-color: var(--light-blue-color);
}

.customBorderRadius {
  border-radius: 9px;
}

.customButton {
  border-radius: 10px;
  letter-spacing: 1px;
  padding: 1rem 2rem;
}

.customSmButton {
  border-radius: 5px;
  // letter-spacing: 1px;
  padding: .5rem 1rem;
}

.customPrimaryButton {
  background-color: var(--primary-color);
  color: #ffffff;
}

.customGreyButton {
  background-color: var(--grey-color);
}

.customPrimaryButton:hover {
  color: #ffffff;
}

.customFormCard {
  background-color: #ffffff;
  padding: 1.1rem;
  box-shadow: 0px 20px 40px rgba(196, 196, 196, 0.15);
}

.modalRight {
  @media only screen and (min-width: 768px) {
    position: fixed;
    right: 0;
  }
}

.w-md-50 {
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}

.cursorPointer {
  cursor: pointer;
}

.removeScrollbar::-webkit-scrollbar {
  display: none;
}

.extraSmallText {
  font-size: 0.7rem;
}

@media only screen and (min-width: 576px) {
  .customFormCard {
    background-color: #ffffff;
    padding: 1.3rem;
  }
}

/* Resets (customisations) on bootstrap native classes */

.modal-content {
  border-radius: 18px;
}