body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* input[type="number"]::-webkit-inner-spin-button, */
.react-code-input input[type="number"]::-webkit-outer-spin-button {
  opacity: 1 !important;
  -webkit-appearance: none;
}

@layer utilities {
  .rm-drag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  .overflowed {
    overflow: auto;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .overflowed::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: transparent;
  }

  .overflowed::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #ccc;
  }
  .gradient1 {
    background: linear-gradient(90deg, #d7ff99 4.81%, #0b6b22 101.51%);
  }
  @keyframes fadeAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .cfade {
    animation: fadeAnimation 1.4s ease-in-out -0.4s;
    opacity: 1;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #5e6366;
    -webkit-box-shadow: 0 0 0px 1000px #ffff inset;
  }
}

@layer components {
  .hsection {
    @apply px-4 py-2 max-w-[68rem] mx-auto;
  }
  .react-code-input.auth input {
    appearance: textfield;
    -moz-appearance: textfield;
    @apply border-[.5px] border-neutral-1 transition-all duration-300 font-sans font-bold text-lg outline-none focus:border-light-blue rounded-[.5rem] w-16 h-16 text-center;
  }
  .react-code-input.sm input {
    @apply w-full h-12 sm:h-16;
  }
  .react-code-input.false input {
    @apply border-red-600;
  }
  .gr-btn {
    @apply rounded-[.75rem] min-w-[8rem] bg-neutral-4 text-neutral-1 hover:text-white text-base flex gap-3 items-center justify-center h-14 hover:gradient1 transition-all duration-700;
  }
}
